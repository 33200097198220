import { template as template_e3c7ef66439a4d8e9ed03b931ef3c76a } from "@ember/template-compiler";
const FKText = template_e3c7ef66439a4d8e9ed03b931ef3c76a(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
