import { template as template_8b19997ea5824597a5c5021475f98b3a } from "@ember/template-compiler";
const FKControlMenuContainer = template_8b19997ea5824597a5c5021475f98b3a(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
