import { template as template_7e03b88d45924aab9e6e463572426314 } from "@ember/template-compiler";
const WelcomeHeader = template_7e03b88d45924aab9e6e463572426314(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
